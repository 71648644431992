<template>
    <span :style="{'margin-left': (row.level??0) * levelIndent + 'px'}">
        <template v-if="row.isLoading">
            <span class="spinner-border spinner-border-sm text-primary me-2" role="status" aria-hidden="true"
                style="border-width: var(--bs-spinner-border-width); border-style: solid; border-color: currentColor; border-right-color: transparent;">
            </span>
            {{$t('Loading...')}}
        </template>
        <template v-else-if="row.hasNodes">
            <span class="o365-group-expand me-1 p-2" role="button" @click="row.expanded ? row.collapse() : row.expand()"
                data-grid-ignore-edit>
                <i :class="row.expanded ? 'bi bi-chevron-down' : 'bi bi-chevron-right'"></i>
            </span>
            <slot name="value">
                <span :class="displayClass">
                    {{ displayValue }}
                </span>
            </slot>
            <button v-if="row.canCreateNodes" class="btn btn-sm btn-link float-end me-1 o365-group-expand-add-below d-none py-0"
                @click="addRowBelow">
                <i class="'bi bi-plus-circle"></i>
            </button>
        </template>
        <span v-else>
            <span class="o365-group-expand me-1 p-2">
                <i v-if="!noLeafIndicators" class="'bi bi-dot"></i>
            </span>
            <slot v-if="$slots.default"></slot>
            <slot v-else name="value"></slot>
            <button v-if="row.canCreateNodes" class="btn btn-sm btn-link float-end me-1 o365-group-expand-add-below d-none py-0"
                @click="addRowBelow">
                <i class="'bi bi-plus-circle"></i>
            </button>
        </span>
    </span>
</template>

<script lang="ts">
export interface IProps {
    row: NodeItem,
    displayClass: any,
    levelIndent?: number,
    hideCount?: boolean,
    /** Hide leaf node indicators */
    noLeafIndicators?: boolean,
};
</script>

<script setup lang="ts">
import type { NodeItem } from './DataGrid.NodeItem.ts';
import { computed } from 'vue';
import { $t } from 'o365-utils';

const props = withDefaults(defineProps<IProps>(), {
    levelIndent: 24
});

const displayValue = computed(() => {
    const value = props.row.displayValue ?? $t('Blank');
    return (props.hideCount || !props.row.count) ? value : `${value} (${props.row.count})`;
});

function addRowBelow() {
    window.setTimeout(() => {
        if (props.row.addSibling) {
            props.row.addSibling();
        }
    }, 10);
}

</script>